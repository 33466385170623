export const numberToFactory = (numbers: string[]): string[] => {
  return numbers.map((number) => {
    switch (number) {
      case "1":
        return "גן יבנה";

      case "2":
        return "תפן";

      case "4":
        return "Ypsonas";

      case "5":
        return "Moni";
      case "6":
        return "רחובות";

      case "7":
        return "יבנה";
    
      case "8":
        return "רמלה";

      case "9":
          return "בלומפילד";
      default:
        return "Conext Global";
    }
  });
};

// Function 2: numberToFactorySingle
export const numberToFactorySingle = (number: string): string => {
  switch (number) {
    case "1":
      return "גן יבנה";

    case "2":
      return "תפן";

    case "4":
      return "Ypsonas";

    case "5":
      return "Moni";

    case "6":
      return "רחובות";

    case "7":
      return "יבנה";

    case "8":
        return "רמלה";

        case "9":
          return "בלומפילד";
    default:
      return "Conext Global";
  }
};
