import styled from "styled-components";

const ReadDataStyled = styled.div`
  .box.status {
    max-height: 298px;
    padding: 20px;
  }


  .slump-statuses {
    width: 100%;
    flex-wrap: wrap;
    padding: 20px;
    display: flex;
    gap: 20px;
}

  .box-items {
    background: rgb(255, 255, 255);
    border-radius: 5px;
    cursor: pointer;
    width: 22%;
    text-align: center;
    margin-right: 14px;
    padding: 10px;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    flex-direction: column;
}

svg.fire-icon {
    height: 34px;
    width: 37px;
}

h3.bold.text-center {
    font-size: 19px;
}
.px-4.py-2.black {
    font-size: 21px;
}
.box-items.red {
    background: rgb(251 117 117 / 16%);
}

.box-items.orange {
    background: #ff832469;
}
.box-items.green {
    background: rgb(255 131 36 / 20%);
}

  .active-row {
    background: #72fa9b;
  }

  td {
    font-size: 15px;
    .flex {
      font-size: 13px;
    }
  }


@media screen and (max-width: 600px) {
   .slump-statuses{
     flex-direction: column;
    }
    .box-items{
        width: 100% !important; 
    }

    button.rounded-none {
      padding: 10px 5px;
      font-size: 12px;
  }
}
`;

export { ReadDataStyled };
