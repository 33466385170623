import { Drawer, Spin } from "antd";
import axios from "axios";
import { t } from "i18next";
import { observable } from "mobx";
import { inject, observer } from "mobx-react";
import moment from "moment";
import React from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import CementState from "../../../../components/CementState/CementState";
import MapsGoogle from "../../../../components/Map/MapsGoogle";
import { ConcreteViewStyled } from "./styled";
import { GoogleMap } from "@react-google-maps/api";
import AuthStore from "../../../../stores/auth/auth.store";

interface Props extends RouteComponentProps<any> {
  authStore?: AuthStore;
}

type Map = GoogleMap["state"]["map"];
@inject("authStore")
@observer
class ConcreteView extends React.PureComponent<Props, any> {
  @observable visible: boolean = false;
  @observable mixer_number: number = 1;
  @observable sensors: any = [];
  @observable factory: any = {};
  @observable company: any = {};
  @observable show_bar: boolean = false;
  @observable toggleMixerPop: number | null = null;
  map: Map = null;

  @observable zoomToPoint: boolean = false;
  @observable pointToZoom: { lat: number; long: number } | undefined;

  componentDidMount = () => {
    try {
      this.getFactoryLocation();
      setInterval(() => {
        this.getFactorySensors();
      }, 3000);
      this.getFactorySensors();
    } catch (error) {
      console.error(error);
    }
  };

  getFactoryLocation = async () => {
    // const test = await axios.get(
    //   `${process.env.REACT_APP_API_URL}/company/`
    // );
    // console.log("test", test);

    let factory_id = JSON.parse(localStorage.getItem("b_user")).factory_id;

    const rsp = await axios.get(
      `${process.env.REACT_APP_API_URL}/factories/${factory_id}`
    );

    this.factory = rsp.data;
    const company = await axios.get(
      `${process.env.REACT_APP_API_URL}/company/${rsp.data.group_id}`
    );

    this.company = company.data;
  };

  getFactorySensors = async () => {
    let factory_id = JSON.parse(localStorage.getItem("b_user")).factory_id;
    let rsp = await axios.get(
      `${process.env.REACT_APP_API_URL}/sensors?factory_id=${factory_id}&sensor_type=1`
    );

    this.sensors = rsp.data.sensors_data.sort((a: any, b: any) => {
      return a.sensor_id - b.sensor_id;
    });
  };

  closePop = () => {
    this.visible = false;
  };

  openPop = (mixerNumber: number) => {
    this.mixer_number = mixerNumber;
    this.visible = true;
  };

  render() {
    return (
      <ConcreteViewStyled onClick={() => this.closePop()}>
        {this.factory.lat ? (
          <div className="">
           
            <MapsGoogle
              company={this.company}
              openPop={(i) => this.openPop(i)}
              toggleMixerPop={this.toggleMixerPop}
              factoryImg={this.props.authStore!.getUserLogo()}
              factory={this.factory}
              sensors={this.sensors}
              onLoad={(map) => {
                this.map = map;
              }}
            />

            {this.show_bar ? (
              <div className="sensor_group_1">
                <div className="sensor_status_item  scrollbar flex flexCol gap20">
                  <div className="flex full justify-between">
                    <div className="title">{t("mixers")}</div>

                    <svg
                      className="arrow"
                      onClick={() => (this.show_bar = !this.show_bar)}
                      width="50"
                      height="50"
                      viewBox="0 0 50 50"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect width="50" height="50" rx="5" fill="#FF6700" />
                      <path
                        d="M21.6667 18.3333L28.3334 24.9999L21.6667 31.6666"
                        stroke="#2D2D2D"
                        strokeWidth="2"
                        strokeLinecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>

                  <div className="flex select-filter">
                    <div className="item active">{t("all")}</div>
                    <div className="item">{t("waiting")} (23)</div>
                    <div className="item">{t("off")} (10)</div>
                  </div>

                  <div className="flex flex-col height-scroll scrollbar full">
                    {this.sensors?.map((sensor: any) => {
                      return (
                        <div
                          className="sensor cursor-pointer"
                          key={sensor.sensor_id}
                        >
                          <div
                            className=""
                            onClick={() => {
                              setTimeout(() => {
                                this.map.setCenter({
                                  lat: Number(sensor.lat),
                                  lng: Number(sensor.long),
                                });
                                this.map.setZoom(16);
                              });
                              this.toggleMixerPop = sensor.sensor_id;
                            }}
                          >
                            {t("sensor")}: {sensor.sensor_id}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            ) : (
              <div className="sensor_group_1 close_bar">
                <div className="sensor_status_item   flex flexCol gap20 full">
                  <div className="flex">
                    <svg
                      className="arrow"
                      onClick={() => (this.show_bar = !this.show_bar)}
                      width="50"
                      height="50"
                      viewBox="0 0 50 50"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <rect
                        x="50"
                        y="50"
                        width="50"
                        height="50"
                        rx="5"
                        transform="rotate(-180 50 50)"
                        fill="#FF6700"
                      />
                      <path
                        d="M28.3333 31.6666L21.6666 25L28.3333 18.3333"
                        stroke="#2D2D2D"
                        strokeWidth="2"
                        strokeLinecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>

                  <div className="flex flex-col height-scroll full">
                    {this.sensors?.map((sensor: any) => {
                      return (
                        <div
                          className="sensor cursor-pointer"
                          key={sensor.sensor_id}
                          onClick={() => {
                            setTimeout(() => {
                              this.map.setCenter({
                                lat: Number(sensor.lat),
                                lng: Number(sensor.long),
                              });
                              this.map.setZoom(16);
                            });
                            this.toggleMixerPop = sensor.sensor_id;
                          }}
                        >
                          <div className="">{sensor.sensor_id}</div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            )}
          </div>
        ) : (
          // <Spin
          //   className="center_loading"
          //   tip="טוען מידע עדכני על ערבלים..."
          // ></Spin>
          <div></div>
        )}

        <Drawer
          title={`ערבל מס' ${this.mixer_number}`}
          closable={false}
          open={this.visible}
          placement={"left"}
          destroyOnClose
        >
          <CementState cementType="עדש" deviceId={this.mixer_number} />
        </Drawer>

        {/* <SensoreLocaionInFactory  /> */}
      </ConcreteViewStyled>
    );
  }
}
export default withRouter(ConcreteView);
